<template>
  <el-dialog 
    :title="itemId ? '修改':'新增'"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <gl-wrap right="30px">
      <el-form :model="formData" ref="form" label-width="100px" width="500px" size="mini" v-loading="loading" >
        <el-form-item prop="userName" label="姓名" verify>
          <el-input v-model="formData.userName"></el-input>
        </el-form-item>
        <el-form-item prop="account" label="账号名称" verify>
          <el-input v-model="formData.account"></el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码" verify>
          <el-input v-model="formData.password"></el-input>
        </el-form-item>
        <el-form-item prop="phone" label="手机号" verify>
          <el-input v-model="formData.phone"></el-input>
        </el-form-item>
        <el-form-item prop="userType" label="权限组" verify>
          <gl-option v-model="formData.userType" :list="authList"></gl-option>
        </el-form-item>
      </el-form> 
    </gl-wrap>
    <gl-flex>
      <el-button type="primary" @click="onSubmit" >确认</el-button>
      <el-button @click="handleClose" >关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
export default {
  name: 'management-update',
  props: {
    itemId: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
      authList: [
        {name: '管理员', value: 'admin'},
        {name: '用户', value: 'user'}
      ]
    }
  },
  watch: {
    isRequest: {
      handler: function(newVal){   
        newVal && this.getDetail()
      }
    },
  },
  computed: {
    isRequest(){
      if(this.visible && this.itemId) return true
      return false
    },
  },
  methods: {
    async onSubmit(){

      this.$refs.form.validate(async valid => {
        if(!valid) return false
        console.log('formData:', this.formData)

        this.loading = true
        // const res = null
        // if(this.itemId){
        //   res = await update(this.formData)
        // }else{
        //   res = await add(this.formData)
        // }
        setTimeout(() => {
          this.loading = false
          this.$message.success('操作成功')
          this.handleClose()
          this.$emit('reload')
        },1000)
      })
        
      
    },
    async getDetail(){

      this.loading = true
      // const res = await getDetail(this.itemId)
      const sleep = (time = 1000) => new Promise(resolve => setTimeout(resolve, time ))
      await sleep()
      const res = {
        data: {
          id: 'id888888888',
          title: '我是标题',
          phone: '5555555555',
          specification: '公仔',
          consignee: 'evan',
          paymentMethod: 1,
          userName: '彭虎',
          account: 'evan',
          userType: "admin",
        }
      }
      this.formData =  res.data
      this.loading = false
    },
    handleClose(){
      this.$emit('update:visible', false)
      // this.$nextTick(() => this.$refs.form.resetFields() )  //重置表单
      setTimeout(() => {
        this.$refs.form.resetFields()
        this.formData = {}
      }, 100)
    },
  },
}
</script>

<style>

</style>