<template>
  <div class="order-management">
    <gl-title :title="$route.meta.title"></gl-title>
    <div style="margin: 18px;">
      <el-button type="primary" size="mini" @click="onClickAction('updateVisible')"> 新增 </el-button>
    </div>
    <gl-card v-loading="mLoading">
      <el-table :data="mTableData" style="width: 100%">
        <el-table-column type="index" :index="mIndexMethod" label="序号" align="center" width="44"></el-table-column>
        <el-table-column prop="userName" label="姓名"></el-table-column>
        <el-table-column prop="account" label="账号名称"></el-table-column>
        <el-table-column prop="phone" label="手机号"></el-table-column>
        <el-table-column prop="userType" label="权限组"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button type="text" @click="onClickAction('previewVisible', row)"> 查看 </el-button>
            <el-button type="text" @click="onClickAction('updateVisible', row)"> 修改 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <gl-pagination
        :total="mTotal"
        :current-page="mPageNum"
        @currentChange="mHandleCurrentChange"
        @sizeChange="mHandleSizeChange">
      </gl-pagination>
    </gl-card>
    <update :itemId="itemId" :visible.sync="updateVisible" @reload="mReload"/>
    <detail :itemId="itemId" :visible.sync="previewVisible"></detail>
  </div>
</template>

<script>
import ListMixin from '@/mixins/list.mixin.js'
import update from './update.vue'
import detail from './detail.vue'

export default {
  components: { update, detail },
  mixins: [ListMixin],
  data(){
    return{
      previewVisible: false,
      updateVisible: false,
      itemId: '',
    }
  },
  methods: {
    onClickAction(name, item){
      this.itemId = item?.id || ''
      this[name] = true
    }
  }
}
</script>

<style lang="less">


</style>